

































import {
  PropType,
  computed,
  defineComponent,
  onMounted,
  ref,
  useContext,
  useFetch,
  watch
} from '@nuxtjs/composition-api';
import type { CtaInterface, ArticleCardListRows } from '@amplience/types';
import { useSearch } from '@amplience/composables/useSearch';
import ArticleCardRow from '@amplience/components/molecules/ArticleCardRow.vue';
import SkeletonArticleCardList from '@amplience/components/skeleton/ArticleCardList.vue';
import CtaPartial from '@theme/components/molecules/CtaPartial.vue';
import compact from 'lodash-es/compact';
import { useWindowSize } from '@vueuse/core';
import { breakpoints } from '~/utils/breakpoints';

export default defineComponent({
  name: 'ArticleCardList',
  components: {
    ArticleCardRow,
    CtaPartial,
    SkeletonArticleCardList,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: Array as PropType<ArticleCardListRows[]>,
      default: () => [],
    },
    cta: {
      type: Object as PropType<CtaInterface>,
      default: () => ({})
    },
    backgroundColour: {
      type: String,
      default: 'Neutral-0 - White'
    }
  },
  setup(props) {
    const context = useContext();
    const { search: algoliaSearch, state } = useSearch(context);
    const deliveryIds = ref<string[]>([]);
    const { width: windowWidth } = useWindowSize({
      includeScrollbar : false
    });
    const { width: screenWidth } = useWindowSize({
      includeScrollbar : true
    });
    const styleWidth = ref<string>('');

    const ctaParams = (cta: CtaInterface) => {
      return {
        linkType: cta.linkType,
        title: cta.title || '',
        url: cta.url || '',
        type: cta.type || 'Primary',
        newWindow: !!(cta.newWindow),
        noFollow: !!(cta.noFollow),
        trackingQuery: cta.trackingQuery || '',
      }
    };

    const handleRequest = async (): Promise<void> => {
      if (props.content) {
        props.content.map((item: any) => {
          deliveryIds.value = deliveryIds.value.concat(item.rows.map((row) => row.id));
        });
      }

      if (deliveryIds.value.length) {
        await algoliaSearch({
          indexName: 'articleSlots',
          params: {
            filters: [{
              fieldName: 'deliveryId',
              conditions: {
                in: deliveryIds.value,
              },
            }],
          },
        });
      }
    };

    useFetch(handleRequest);

    const rowsGetter = (rows): any[] => {
      if (state.items) {
        return compact(rows.map((item) => state.items.find((row) => row.deliveryId === item.id)));
      }
    };

    // transform article rows into one row for mobile and tablet
    const transformedContent = (content): ArticleCardListRows[] => {
      const transformedArray = content.reduce((acc, current) => {
        const existingRows = acc.length > 0 ? acc[acc.length - 1].rows : [];

        return [...acc.slice(0, acc.length - 1), {
          type: 'transformed',
          rows: [...existingRows, ...current.rows]
        }];
      }, []);

      return transformedArray
    }

    const cardRows = computed(() => {
      // This is needed to fix SSR issue
      if (screenWidth.value === Infinity || !screenWidth.value) {
        return props.content;
      }

      return screenWidth.value >= breakpoints.desktop
        ? props.content
        : transformedContent(props.content);
    });

    watch(() => windowWidth.value, (newValue) => {
      if (newValue) {
        styleWidth.value = `--content-width: ${newValue}px`;
      }
    });

    onMounted(() => {
      styleWidth.value = `--content-width: ${windowWidth.value}px`;
    })

    return {
      cardRows,
      breakpoints,
      ctaParams,
      state,
      styleWidth,
      screenWidth,
      windowWidth,
      transformedContent,
      rowsGetter,
      isCallToAction: (cta: CtaInterface): boolean => !!(cta?.title && cta?.type && cta?.url),
    };
  },
});
